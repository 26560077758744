<template>
  <form
    id="braForm"
    action
    data-vv-scope="brand-form"
    @submit.prevent="validateForm('brand-form')"
  >
    <v-row style="display: flex; justify-content: center">
      <v-col class="py-0" cols="12" md="10">
        <label style="display: block; height: 25px !important">{{
          $t("brand.name")
        }}</label>
        <v-text-field
          outlined
          dense
          data-vv-validate-on="blur"
          v-model="brand.brand_name"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('brand-form.name')"
          color="secondary"
          data-vv-name="name"
        />
      </v-col>
      <slot></slot>
    </v-row>
    <div
      class="pa-0 text-right"
      style="margin-right: -10px"
      v-if="buttons !== false"
    >
      <v-btn
        outlined
        @click="$emit('back')"
        style="width: 100px !important; height: 30px"
        class="mr-3"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        style="
          width: 100px !important;
          color: #363533;
          height: 30px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>
<script>
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "BrandsForm",
  props: ["title", "brand", "buttons"],
  mounted() {},
  data() {
    return {
      userProcessed: false,
      menu: false,
      brand: [],
    };
  },
  methods: {
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      if (result) {
        this.$emit("validated", this.brand);
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="sass">
.inputs .v-input__slot
  margin-top: -10px

#braForm
  label
    display: block
    height: 35px !important
</style>
